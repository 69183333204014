import { ESuggestedProductViewId } from 'core/model/enums/suggested-products.enum';
import { ISuggestionSection } from 'core/model/interfaces/suggested-products.interface';
import { suggestedProductsService } from 'core/services/suggested-products/suggested-products.service';
import { type TSliceExtraReducer } from 'core/store';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { ISuggestedProductsState } from '../types';

export const getSuggestedProducts = createAppAsyncThunk<Array<ISuggestionSection>, ESuggestedProductViewId>(
  `${SLICE_NAMESPACE}/getSuggestedProducts`,
  (locationId: ESuggestedProductViewId, { getState }) => {
    const state = getState();
    const { checkout } = state;
    const customerId = getCustomerIdFromCheckout(checkout);

    return suggestedProductsService.getSuggestions(
      {
        viewId: locationId,
      },
      customerId,
    );
  },
);

export const getSuggestedProductsReducer: TSliceExtraReducer<ISuggestedProductsState> = builder => {
  builder
    .addCase(getSuggestedProducts.pending, (state, action) => {
      const viewId = action.meta.arg;
      const section = state.sections[viewId];
      state.sections[viewId] = {
        ...section,
        isLoading: true,
      };
    })
    .addCase(getSuggestedProducts.rejected, (state, action) => {
      const viewId = action.meta.arg;
      state.sections[viewId] = {
        isLoading: false,
        suggestions: [],
      };
    })
    .addCase(getSuggestedProducts.fulfilled, (state, action) => {
      const viewId = action.meta.arg;
      const { payload } = action;
      state.sections[viewId] = {
        isLoading: false,
        suggestions: payload,
      };
    });

  return builder;
};
