import { IOrdersFilters } from 'core/model/interfaces/order.interface';
import { ordersService } from 'core/services/orders/orders.service';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { IOrdersState } from '../types';

export const getOrdersAction = createAppAsyncThunk(`${SLICE_NAMESPACE}/getOrders`, (params: IOrdersFilters) =>
  ordersService.getOrders(params),
);

export const getOrdersReducer: TSliceExtraReducer<IOrdersState> = builder => {
  builder
    .addCase(getOrdersAction.pending, state => {
      state.loading = true;
    })
    .addCase(getOrdersAction.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.ordersList = payload;
      state.error = null;
    })
    .addCase(getOrdersAction.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });

  return builder;
};
