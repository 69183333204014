import { IVisitorProvider } from 'core/model/interfaces/visitor.interface';

export class VisitorGeneric {
  private provider: IVisitorProvider;
  private static instance: VisitorGeneric | null = null;
  private installed = false;

  constructor(provider: IVisitorProvider) {
    this.provider = provider;
  }

  static getInstance(provider: IVisitorProvider): VisitorGeneric {
    if (!this.instance) {
      this.instance = new VisitorGeneric(provider);
    }
    return this.instance;
  }

  install() {
    if (this.installed) {
      return;
    }
    this.installed = true;
    this.provider.install();
  }

  getInformation() {
    return this.provider.getInformation();
  }
}
