import { catalogsService } from 'core/services/catalogs/catalogs.service';
import type { TSliceExtraReducer } from 'core/store';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { IProductDetailState } from '../types';

export const getProductDetailAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/getProductDetail`,
  (sku: string, { getState }) => {
    const state = getState();
    const { checkout } = state;
    const customerId = getCustomerIdFromCheckout(checkout);

    return catalogsService.getProductDetail({ sku }, customerId);
  },
);

export const getProductDetailReducer: TSliceExtraReducer<IProductDetailState> = builder => {
  builder
    .addCase(getProductDetailAction.pending, state => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getProductDetailAction.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = null;
    })
    .addCase(getProductDetailAction.rejected, (state, { error }) => {
      state.data = null;
      state.loading = false;
      state.error = error;
    });

  return builder;
};
