import { Button } from '@insuma/mpp-ui/components/button';
import { Heading, Text } from '@insuma/mpp-ui/components/typography';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { useScreen } from 'core/contexts/screen.context';
import { MainContainer } from 'shared/components/main-components';
import { MovingEye } from 'shared/components/moving-eye';

import './rollback-internal-error.scss';

export const RollbackInternalError = () => {
  const css = useCSS('rollback-internal-error');
  const { width } = useScreen();
  const isMobile = width <= 880;

  return (
    <MainContainer className={css()}>
      <Heading size="4xl" mb="1rem" isResponsive>
        ¡Ups, alg{isMobile ? 'o' : ''}
        <MovingEye className={css('moving-eye')} /> <MovingEye className={css('moving-eye')} />
        {isMobile ? 'o' : ''}currió!
      </Heading>

      <Text mt="0.5rem" mb="1rem" size="md" isResponsive>
        Lo arreglaremos en un instante.
      </Text>
      <Button size="sm" onClick={() => window.location.assign(window.location.origin)}>
        Regresar al inicio
      </Button>
    </MainContainer>
  );
};
