import { ROUTES } from 'core/constants/routes.constants';
import { ICheckoutDetail, TDistributorSource } from 'core/model/interfaces/checkout.interface';
import { checkoutService } from 'core/services/checkout/checkout.service';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { handleServiceException } from 'shared/utils/errors.utils';
import { checkoutObservability } from 'shared/utils/observability/checkout.observability';
import { getCustomerIdFromCheckout, mapToInitialRefetchCheckoutParams } from '../checkout-state.utils';
import { checkoutInitialState, resetCheckoutLoading, SLICE_NAMESPACE } from '../constants';
import { ICheckoutState } from '../types';

export const refetchCheckoutDetailAction = createAppAsyncThunk<ICheckoutDetail, TDistributorSource>(
  `${SLICE_NAMESPACE}/refetchCheckoutDetail`,
  async (distributor, { getState, rejectWithValue }) => {
    const { auth, checkout } = getState();
    const customerSource = auth.customer.source;
    try {
      const customerId = getCustomerIdFromCheckout(checkout);
      const params = mapToInitialRefetchCheckoutParams({
        distributor,
        customerSource,
        paymentMethod: checkout.paymentMethod,
      });
      return await checkoutService.getCartDetail(customerId, params);
    } catch (e) {
      const error = handleServiceException(e);
      checkoutObservability.saveCheckoutError({
        step: ROUTES.CHECKOUT_PURCHASE,
        details: checkout.detail,
        customerSource,
        error,
      });
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw rejectWithValue(error);
    }
  },
);

export const refetchCheckoutDetailReducer: TSliceExtraReducer<ICheckoutState> = builder => {
  builder
    .addCase(refetchCheckoutDetailAction.pending, (state, { meta }) => {
      const distributor = meta.arg;
      state.error.global = null;
      state.loading[distributor] = true;
      state.error[distributor] = null;
    })
    .addCase(refetchCheckoutDetailAction.fulfilled, (state, { payload }) => {
      state.error = checkoutInitialState.error;
      state.loading = resetCheckoutLoading;
      state.detail = payload;
    })
    .addCase(refetchCheckoutDetailAction.rejected, (state, { error, meta }) => {
      state.loading = resetCheckoutLoading;
      state.error[meta.arg] = error;
    });

  return builder;
};
