import { ATTRIBUTES } from 'core/constants/observability.constants';
import { TAttributes, TObservabilityValue } from 'core/model/interfaces/observability.interface';
import { isLocalEnv } from '../env.utils';

interface ISaveAction<T> {
  action: string;
  attributes: Record<string, TObservabilityValue>;
  type: T;
}

export const saveAction = <T extends keyof TAttributes>({
  action,
  attributes: inputAttributes,
  type,
}: ISaveAction<T>): void => {
  setTimeout(() => {
    const attributes = Object.entries(inputAttributes).reduce((acc, [key, value]) => {
      const attribute = ATTRIBUTES[type] as Record<string, string>;
      acc[attribute[key]] = value;
      return acc;
    }, {} as Record<string, TObservabilityValue>);

    // eslint-disable-next-line no-console
    if (isLocalEnv) console.log('new relic:', { action, attributes, type });

    if (!window.newrelic) return;

    window.newrelic.addPageAction(action, attributes);
  }, 0);
};

export const saveAttributesInStorage = <T extends keyof TAttributes>(
  type: T,
  attributes: { [key in keyof TAttributes[T]]?: TObservabilityValue },
): void => {
  Object.entries(attributes).forEach(([key, value]) => {
    localStorage.setItem(`o11y_${type}_${key}`, String(value));
  });
};

export const setAttribute = <T>(attribute: string, value: T) => {
  // eslint-disable-next-line no-console
  if (isLocalEnv) console.log('new relic: global attributes set', attribute, value);

  if (!window.newrelic) return;
  window.newrelic.setCustomAttribute(attribute, value);
};

export const removeAttributesFromStorage = <T extends keyof TAttributes>(type: T): void => {
  Object.keys(ATTRIBUTES[type]).forEach(key => localStorage.removeItem(`o11y_${type}_${key}`));
};

export const startTransition = (fn: VoidFunction) =>
  typeof queueMicrotask === 'undefined' ? setTimeout(fn, 0) : queueMicrotask(fn);
