import { customersService } from 'core/services/customers/customers.service';
import { type TSliceExtraReducer } from 'core/store';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { ICreditsState } from '../types';

export const loadCreditLimitsAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/loadCreditLimitsAction`,
  (_, { getState }) => {
    const { checkout } = getState();
    const customerId = getCustomerIdFromCheckout(checkout);
    return customersService.getCreditLimits(customerId);
  },
);

export const loadCreditLineReducer: TSliceExtraReducer<ICreditsState> = builder =>
  builder
    .addCase(loadCreditLimitsAction.pending, state => {
      state.loading = true;
    })
    .addCase(loadCreditLimitsAction.fulfilled, (state, { payload }) => {
      state.creditLimits = payload.creditLimits;
      state.loading = false;
      state.error = null;
    })
    .addCase(loadCreditLimitsAction.rejected, (state, { error }) => {
      state.error = error;
    });
