import { ATTRIBUTES } from 'core/constants/observability.constants';
import { ICustomer } from 'core/model/interfaces/customer.interface';
import { TOservabilityAttributes } from 'core/model/interfaces/observability.interface';
import { generateUUIDv4 } from '../uuid.utils';
import { saveAction, saveAttributesInStorage, setAttribute, startTransition } from './observability-transformer';

export const userObservability = {
  setGlobalCustomerAttributes: (customer: ICustomer) => {
    startTransition(() => {
      const { document, name } = customer.basicData;
      const userDocumentId = window.localStorage.getItem('user_document_id');
      let uuid = window.localStorage.getItem('o11y_customer_uuid');
      if (!uuid || userDocumentId !== document.number) {
        uuid = generateUUIDv4();
        const userLoginAttributes: TOservabilityAttributes<'userLogin'> = {
          credentialType: document.type,
          credentialValue: document.number,
          error: 'false',
          message: '',
          uuid,
        };
        saveAction({ action: 'UserLogin', type: 'userLogin', attributes: userLoginAttributes });
      }

      const attributes: TOservabilityAttributes<'customer'> = {
        documentType: document.type,
        documentNumber: document.number,
        username: name,
        type: customer.source,
        uuid,
      };

      Object.keys(ATTRIBUTES.customer).forEach(field => {
        const key = field as keyof typeof attributes;
        const attribute = ATTRIBUTES.customer[key];
        const value = attributes[key];
        setAttribute(attribute, value);
      });

      saveAttributesInStorage('customer', attributes);
    });
  },
};
