/* eslint-disable no-console */
import type { Agent } from '@fingerprintjs/fingerprintjs-pro';
import { FINGERPRINT_API_KEY, FINGERPRINT_ENDPOINT } from 'core/constants/general.constants';
import { IVisitorInformation, IVisitorProvider } from 'core/model/interfaces/visitor.interface';
import { isProduction, isTestingEnv } from '../env.utils';

export class FingerprintProvider implements IVisitorProvider {
  private fingerprintAgent: Promise<Agent> | null = null;
  async install() {
    if (!FINGERPRINT_API_KEY) {
      return;
    }
    const FingerprintJSModule = await import('@fingerprintjs/fingerprintjs-pro');
    this.fingerprintAgent = FingerprintJSModule.load({
      apiKey: FINGERPRINT_API_KEY,
      endpoint: [FINGERPRINT_ENDPOINT, FingerprintJSModule.defaultEndpoint],
      scriptUrlPattern: [
        `${FINGERPRINT_ENDPOINT}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
        FingerprintJSModule.defaultScriptUrlPattern,
      ],
    });
  }

  async getInformation(): Promise<IVisitorInformation> {
    if (!isProduction() && !isTestingEnv()) {
      return { id: null };
    }

    if (!this.fingerprintAgent) {
      console.warn('FingerprintJS is not initialized');
      return { id: null };
    }

    const agent = await this.fingerprintAgent;
    const { visitorId } = await agent.get();

    return { id: visitorId };
  }
}
