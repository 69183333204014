import ReactDOM from 'react-dom';
import { EventType } from '@azure/msal-browser';
import store from 'core/store';
import { updateJustLoggedIn } from 'core/store/auth';
import msalPublicClientApplication from 'shared/utils/msal.utils';
import { saveTokenToLocalStorage } from 'shared/utils/token.utils';
import App from './screens/app';
import reportWebVitals from './reportWebVitals';

import './index.scss';

msalPublicClientApplication.initialize().then(() => {
  msalPublicClientApplication.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      // eslint-disable-next-line no-console
      console.warn('login success triggered');
      store.dispatch(updateJustLoggedIn(true));
    }
  });

  msalPublicClientApplication
    .handleRedirectPromise()
    .then(res => {
      if (res) saveTokenToLocalStorage(res.accessToken);
    })
    .finally(() => {
      ReactDOM.render(<App />, document.getElementById('root'));
    });
});

reportWebVitals();
