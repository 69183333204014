import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '@insuma/mpp-ui/components/form';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { Path } from 'core/constants/path.constants';
import { accessibleOnClick } from 'shared/utils/a11y.utils';

import './custom-search-input.scss';

export interface ICustomSearchInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  placeholder: string;
  className: string;
  isFocused: boolean;
  isFocus: (focus: boolean) => void;
  onChange: (e: any) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onCancel: (e: any) => void;
}

const TIME_TO_CLOSE_PREVIEW = 250; // HACK: 🧑‍💻 ed42b7c2 - Tiempo para asegurarnos que se dispare el evento onClick en el preview

const SearchPreviewResults = lazy(() => import('../search-preview-results'));

export const CustomSearchInput = ({
  value,
  setValue,
  onCancel,
  onChange,
  onClick,
  isFocused,
  isFocus,
  placeholder,
  className,
  searchValue,
}: ICustomSearchInputProps) => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocusedOnce, setIsFocusedOnce] = useState(false);

  useEffect(() => {
    if (isFocused) inputRef.current?.focus();
  }, [isFocused]);

  const handleKeyUp = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyEvent.key === 'Escape') {
      setValue('');
      inputRef.current?.blur();
      return;
    }
    if (keyEvent.key === 'Enter' && value.length >= 3) {
      navigate(`${Path.SEARCH}?q=${value}`);
      inputRef.current?.blur();
    }
  };

  return (
    <div className={`custom-search-input ${className}`}>
      <div {...accessibleOnClick(() => isFocus(true))} className="custom-search-input__container">
        <Icon name="magnifying-glass" className="custom-search-input__search-icon" variant="primary" />
        <Input
          ref={inputRef}
          className="custom-search-input__input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onFocus={() => {
            isFocus(true);
            setIsFocusedOnce(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              isFocus(false);
            }, TIME_TO_CLOSE_PREVIEW);
          }}
          onKeyUp={handleKeyUp}
          withoutFormControl
        />
        {value.length > 0 && <Icon className="custom-search-input__clear" name="x" onClick={onCancel} />}
      </div>

      <Suspense fallback={null}>
        {isFocusedOnce && <SearchPreviewResults inputValue={value} isFocused={isFocused} searchValue={searchValue} />}
      </Suspense>
    </div>
  );
};
