import { ECartActions, ECartError } from 'core/model/enums/shopping-cart.enum';
import { shoppingCartService } from 'core/services/shopping-cart/shopping-cart.service';
import type { TSliceExtraReducer } from 'core/store';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { CART_ERRORS_INITIAL_STATE, EMPTY_SHOPPING_CART_GROUPS, SLICE_NAMESPACE } from '../constants';
import { ICartState } from '../types';

export const getCartAction = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/getCart`,
  async (payload: { cartAction: ECartActions; shouldRetry?: boolean } | void, { getState }) => {
    const { checkout } = getState();
    const customerId = getCustomerIdFromCheckout(checkout);
    const getShoppingCartParams = payload ? { cartAction: payload.cartAction } : undefined;
    try {
      const response = await shoppingCartService.getShoppingCart(
        customerId,
        getShoppingCartParams,
        payload?.shouldRetry,
      );
      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(ECartError.SHOW_ERROR_AND_CLOSE_CART);
    }
  },
);

export const getCartReducer: TSliceExtraReducer<ICartState> = builder => {
  builder
    .addCase(getCartAction.pending, state => {
      state.cartLoading = true;
      state.success = false;
      state.errors = CART_ERRORS_INITIAL_STATE;
    })
    .addCase(getCartAction.fulfilled, (state, { payload }) => {
      state.itemsGroups = payload.itemsGroups || EMPTY_SHOPPING_CART_GROUPS;
      state.deletedProducts = payload.deletedProducts;
      state.amounts = payload.amounts;
      state.cartLoading = false;
      state.success = true;
      state.errors = { userValidation: payload.errorType, getData: null };
    })
    .addCase(getCartAction.rejected, (state, { error }) => {
      state.cartLoading = false;
      state.success = false;
      if (error?.message) state.errors.getData = error?.message;
    });

  return builder;
};
