import { catalogsService } from 'core/services/catalogs/catalogs.service';
import { type TSliceExtraReducer } from 'core/store';
import { getCustomerIdFromCheckout } from 'core/store/checkout/checkout-state.utils';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { IHighlightedProductsState } from '../types';

export const getHighlightedProducts = createAppAsyncThunk(
  `${SLICE_NAMESPACE}/getHighlightedProducts`,
  (_, { getState }) => {
    const state = getState();
    const { checkout } = state;
    const customerId = getCustomerIdFromCheckout(checkout);
    return catalogsService.getHighlightedProductsPreview(customerId);
  },
);

export const getHighlightedProductsReducer: TSliceExtraReducer<IHighlightedProductsState> = builder => {
  builder
    .addCase(getHighlightedProducts.pending, state => {
      state.featured.loading = true;
    })
    .addCase(getHighlightedProducts.fulfilled, (state, { payload }) => {
      state.featured.loading = false;
      state.featured.products = payload.products;
      state.featured.hasMoreProducts = payload.hasMoreProducts;
      state.featured.title = payload.title;
      state.featured.totalProducts = payload.totalProducts;
    })
    .addCase(getHighlightedProducts.rejected, state => {
      state.featured.loading = false;
      state.featured.products = [];
      state.featured.hasMoreProducts = false;
    });
  return builder;
};
