import { UNEXPECTED_ERROR_CODE, UNEXPECTED_ERROR_MESSAGE } from 'core/constants/error-http.constants';
import { ATTRIBUTES } from 'core/constants/observability.constants';
import { Path } from 'core/constants/path.constants';
import { ROUTES } from 'core/constants/routes.constants';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { EOrderCreatedStatus } from 'core/model/enums/orders.enum';
import { EProductType } from 'core/model/enums/product.enum';
import { ICheckoutDetail } from 'core/model/interfaces/checkout.interface';
import { IServiceError } from 'core/model/interfaces/error.interface';
import { TOservabilityAttributes } from 'core/model/interfaces/observability.interface';
import { IOrderSuccess } from 'core/model/interfaces/order.interface';
import type { ICheckoutError } from 'core/store/checkout';
import { hasProductsInGroupingRules } from 'core/store/checkout/checkout-state.utils';
import { generateUUIDv4 } from '../uuid.utils';
import {
  removeAttributesFromStorage,
  saveAction,
  saveAttributesInStorage,
  setAttribute,
} from './observability-transformer';

interface ISaveCheckoutError {
  details: ICheckoutDetail;
  customerSource: ECustomerSource;
  error: ICheckoutError;
  step: string;
}

interface IRefreshCheckoutStorage {
  details: ICheckoutDetail;
  step: string;
}

interface ISavePurchaseIntentError {
  details: ICheckoutDetail;
  customerSource: ECustomerSource;
  error?: IServiceError;
  step?: string;
}

interface IStepTracker {
  uuid: null | string;
}

const stepTracker: IStepTracker = {
  uuid: null,
};

const getStepDetails = (step?: string) => {
  if (step === ROUTES.CHECKOUT_SHIPPING) {
    return { lastStep: 1, lastStepTitle: 'Despacho' };
  }
  if (step === ROUTES.CHECKOUT_PURCHASE) {
    return { lastStep: 2, lastStepTitle: 'Confirmar pedido' };
  }
  return { lastStep: 3, lastStepTitle: 'Detalle del pedido' };
};

const getProductTypes = ({ details, customerSource }: ISavePurchaseIntentError) => {
  if (customerSource === ECustomerSource.DEX) return 'productos alicorp';
  if (customerSource === ECustomerSource.APUDEX) return 'productos aliados';
  const hasProductsInAlicorp = hasProductsInGroupingRules(details.alicorpCart);
  const hasProductsInAllied = hasProductsInGroupingRules(details.alliedCart);

  if (hasProductsInAlicorp && !hasProductsInAllied) return 'productos alicorp';
  if (!hasProductsInAlicorp && hasProductsInAllied) return 'productos aliados';
  return 'productos alicorp, productos aliados';
};

export const checkoutObservability = {
  createTracePath: () => {
    if (!stepTracker.uuid) {
      stepTracker.uuid = generateUUIDv4();
      setAttribute(ATTRIBUTES.sale.uuid, stepTracker.uuid);
    }
  },
  clearTracePath: () => {
    stepTracker.uuid = null;
  },
  savePurchaseIntent: (attributes: TOservabilityAttributes<'sale'>) => {
    saveAction({ action: 'PurchaseIntent', type: 'sale', attributes });
    saveAttributesInStorage('sale', attributes);
  },
  refreshCheckoutStorage({ details, step }: IRefreshCheckoutStorage) {
    const { lastStep, lastStepTitle } = getStepDetails(step);
    const attributes: TOservabilityAttributes<'sale'> = {
      lastStep,
      lastStepTitle,
      discount: details.amounts.discount,
      price: details.amounts.price,
      status: '',
      error: 'false',
      message: '',
    };
    saveAttributesInStorage('sale', attributes);
  },
  saveCheckoutError: ({ details, error, step, customerSource }: ISaveCheckoutError) => {
    const { lastStep, lastStepTitle } = getStepDetails(step);
    checkoutObservability.savePurchaseIntent({
      lastStep,
      lastStepTitle,
      productType: getProductTypes({ details, customerSource }),
      discount: details.amounts.discount,
      price: details.amounts.price,
      status: 'failed',
      error: 'true',
      message: error.message || 'Error al cargar',
    });
  },
  saveAbandonedCheckout: ({ details, customerSource, step }: ISavePurchaseIntentError) => {
    if (window.location.pathname.includes(Path.CHECKOUT)) return;
    const { lastStep, lastStepTitle } = getStepDetails(step);
    checkoutObservability.savePurchaseIntent({
      lastStep,
      lastStepTitle,
      productType: getProductTypes({ details, customerSource }),
      discount: details.amounts.discount,
      price: details.amounts.price,
      status: 'abandoned',
      error: 'false',
      message: '',
    });
    checkoutObservability.clearTracePath();
  },
  savePurchaseIntentError: ({ details, error: e, customerSource }: ISavePurchaseIntentError) => {
    const { error = { code: UNEXPECTED_ERROR_CODE, message: UNEXPECTED_ERROR_MESSAGE } } = e || {};
    const { lastStep, lastStepTitle } = getStepDetails(ROUTES.CHECKOUT_PURCHASE);

    const attributes: TOservabilityAttributes<'sale'> = {
      lastStep,
      lastStepTitle,
      productType: getProductTypes({ details, customerSource }),
      price: details.amounts.final,
      discount: details.amounts.discount,
      status: 'failed',
      error: 'true',
      message: error.message,
    };
    saveAction({ action: 'PurchaseIntent', type: 'sale', attributes });
    saveAttributesInStorage('sale', attributes);
  },
  savePurchaseSuccess: (details: IOrderSuccess) => {
    const len = details.orders.length;
    const { lastStep, lastStepTitle } = getStepDetails(ROUTES.CHECKOUT_ORDER_SUCCESS);
    for (let i = 0; i < len; i += 1) {
      const order = details.orders[i];
      const attributes: TOservabilityAttributes<'sale'> = {
        lastStep,
        lastStepTitle,
        price: order.totalAmount,
        discount: order.totalDiscountAmount,
        paymentType: order.paymentMethod,
        status: order.status,
        productType: order.sourceId === EProductType.ALICORP ? 'productos alicorp' : 'productos aliados',
        error: String(order.status === EOrderCreatedStatus.Rejected),
        message: order.status === EOrderCreatedStatus.Rejected ? EOrderCreatedStatus.Rejected : '',
      };
      saveAction({ action: 'Purchase', type: 'sale', attributes });
    }
    removeAttributesFromStorage('sale');
  },
};
