import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import type { TCounterVariant } from '@insuma/mpp-ui/components/product-counter';
import { Path } from 'core/constants/path.constants';
import { EProductOrigin, EZoneEntryMenu } from 'core/model/enums/gtm.enum';
import { IProduct } from 'core/model/interfaces/product.interface';
import { ISuggestionSection } from 'core/model/interfaces/suggested-products.interface';
import { CarouselProducts } from 'shared/components/products/carousel-products';
import { triggerEntryMenuEvent } from 'shared/utils/gtm-events/common.events';
import { triggerViewProductListEvent } from 'shared/utils/gtm-events/products.events';

export interface ICarouselCardSuggestionProps {
  catalog: ISuggestionSection;
  origin: EProductOrigin;
  onSeeAll?: () => void;
  shouldTag?: boolean;
  variant?: TCounterVariant;
  shouldSeeMore?: boolean;
  shouldHideTitle?: boolean;
  suggestedProductType: string;
}

export const CarouselCardSuggestion = ({
  catalog,
  origin,
  onSeeAll,
  suggestedProductType,
  shouldTag = true,
  variant = 'default',
  shouldSeeMore = true,
  shouldHideTitle = false,
}: ICarouselCardSuggestionProps) => {
  const { products, hasMoreProducts, quantity, title } = catalog;

  const totalItems = quantity || products?.length;
  const seeMore = shouldSeeMore && hasMoreProducts;

  const navigate = useNavigate();

  const handleSeeAll = useCallback(() => {
    if (onSeeAll) onSeeAll();

    triggerEntryMenuEvent({
      zone: EZoneEntryMenu.SUGGESTED_PRODUCTS,
      category: title,
      subcategory: 'Todos',
    });

    return navigate(Path.SUGGESTED_PRODUCTS_PAGE, { state: { fromCarousel: true } });
  }, [navigate, title, onSeeAll]);

  const gtmRegisterListProducts = useCallback(
    (visibleProducts: Array<IProduct>) => {
      if (shouldTag) {
        triggerViewProductListEvent({
          products: visibleProducts,
          origin,
        });
      }
    },
    [shouldTag, origin],
  );

  return (
    <CarouselProducts title={title} shouldSeeMore={seeMore} products={products} variant={variant}>
      {!shouldHideTitle && (
        <CarouselProducts.Header totalItems={totalItems}>
          {shouldSeeMore && <CarouselProducts.SeeMoreButton onClick={handleSeeAll} />}
        </CarouselProducts.Header>
      )}
      <CarouselProducts.Body
        origin={origin}
        listName={title}
        onViewCarousel={gtmRegisterListProducts}
        suggestedProductType={suggestedProductType}
      >
        {shouldSeeMore && <CarouselProducts.SeeMoreCard onSeeAllClick={handleSeeAll} />}
      </CarouselProducts.Body>
    </CarouselProducts>
  );
};
