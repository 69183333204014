export const PREFIX = 'alc';

export const ATTRIBUTES = {
  userLogin: {
    credentialType: `${PREFIX}.userLogin.credentialType`,
    credentialValue: `${PREFIX}.userLogin.credentialValue`,
    error: `${PREFIX}.userLogin.error`,
    message: `${PREFIX}.userLogin.message`,
    uuid: `${PREFIX}.userLogin.uuid`,
  },
  customer: {
    type: `${PREFIX}.global.customer.type`,
    documentType: `${PREFIX}.global.customer.documentType`,
    documentNumber: `${PREFIX}.global.customer.documentNumber`,
    username: `${PREFIX}.global.customer.username`,
    uuid: `${PREFIX}.global.customer.uuid`,
  },
  sale: {
    price: `${PREFIX}.sale.price`,
    discount: `${PREFIX}.sale.discount`,
    lastStep: `${PREFIX}.sale.lastStep`,
    lastStepTitle: `${PREFIX}.sale.lastStepTitle`,
    error: `${PREFIX}.sale.error`,
    message: `${PREFIX}.sale.message`,
    status: `${PREFIX}.sale.status`,
    productType: `${PREFIX}.sale.productType`,
    paymentType: `${PREFIX}.sale.paymentType`,
    uuid: `${PREFIX}.sale.uuid`,
  },
} as const;
