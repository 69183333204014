import { Link } from '@insuma/mpp-ui/components/link';

import './see-more-button.scss';

interface ISeeMoreButtonProps {
  onClick: () => void;
  text?: string;
}

export const SeeMoreButton = ({ onClick, text = 'Ver más' }: ISeeMoreButtonProps) => (
  <Link hasIcon icon={{ position: 'right' }} onClick={onClick} className="see-more-button">
    {text}
  </Link>
);
