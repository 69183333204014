import { PAYMENT_METHOD_NAME } from 'core/constants/checkout.constants';
import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { ICheckoutDetail, IPaymentMethodSelected, TDistributorSource } from 'core/model/interfaces/checkout.interface';
import { IOrderSuccess } from 'core/model/interfaces/order.interface';
import { IShoppingCart } from 'core/model/interfaces/shopping-cart.interface';
import { getProductTotalDiscountValue, getSelectedUnitMeasure } from '../products.utils';
import { pushObjectDataLayer } from './gtm.events';

interface ITriggerConfirmPurchaseEvent {
  legalConsentCheckbox?: boolean;
  dataPrivacyCheckbox?: boolean;
}

interface ITriggerSelectedPaymentMethod {
  detail: Partial<ICheckoutDetail>;
  distributor: TDistributorSource;
  method: IPaymentMethodSelected;
}

export const triggerConfirmPurchaseEvent = ({
  legalConsentCheckbox,
  dataPrivacyCheckbox,
}: ITriggerConfirmPurchaseEvent) => {
  let checkbox_obligatorio: string | null = legalConsentCheckbox ? 'Si' : 'No';
  let checkbox_opcional: string | null = dataPrivacyCheckbox ? 'Si' : 'No';

  if (legalConsentCheckbox === undefined) {
    checkbox_obligatorio = null;
    checkbox_opcional = null;
  }

  const data = {
    event: 'virtualEventD8',
    tipoevento: 'interaccion',
    timestamp: new Date().getTime(),
    checkbox_obligatorio,
    checkbox_opcional,
  };
  pushObjectDataLayer(data, triggerConfirmPurchaseEvent.name);
};

export const triggerBeginCheckoutEvent = ({ itemsGroups }: IShoppingCart) => {
  const products = [
    ...itemsGroups.generalItems.products,
    ...itemsGroups.alicorpItems.products,
    ...itemsGroups.alliedItems.products,
  ];
  const items = products.map(item => {
    const { price, presentation: presentacion } = getSelectedUnitMeasure([item.unitMeasure]);
    return {
      item_name: item.name,
      item_id: item.sku,
      price,
      item_brand: item.brand,
      procedencia: item.providerDisplayName,
      item_category: item.categoryName,
      item_category2: item.subcategoryName,
      item_list_id: item.metadata?.itemListName,
      item_list_name: item.metadata?.itemListName,
      dex: item.distributorName,
      index: item.metadata?.index,
      quantity: item.quantity,
      product_ean: item.eanCode || 'no-existe',
      product_sku: item.sku,
      metadata: item.metadata.origin,
      presentacion,
      discount: item.promos.length > 0 ? getProductTotalDiscountValue(item.promos) : 0,
    };
  });

  const data = {
    event: 'begin_checkout',
    ecommerce: {
      currency: DEFAULT_CURRENCY.id,
      items,
    },
  };
  pushObjectDataLayer(data, triggerBeginCheckoutEvent.name);
};

export const triggerSelectedPaymentMethodEvent = ({ detail, method, distributor }: ITriggerSelectedPaymentMethod) => {
  const sourceDetail = detail[distributor];

  const items = (sourceDetail?.groupingRules || [])
    .map(rule => rule.products)
    .flat()
    .map(item => {
      const { price } = getSelectedUnitMeasure([item.unitMeasure]);
      return {
        item_name: item.name,
        item_id: item.sku,
        price,
        item_brand: item.brand,
        procedencia: item.providerDisplayName,
        item_category: item.categoryName,
        item_category2: item.subcategoryName,
        item_list_id: item.metadata?.itemListName,
        item_list_name: item.metadata?.itemListName,
        index: item.metadata?.index,
        quantity: item.quantity,
        product_ean: item.eanCode || 'no-existe',
        product_sku: item.sku,
      };
    });
  const data = {
    event: 'add_payment_info',
    ecommerce: {
      currency: DEFAULT_CURRENCY.id,
      payment_type: method.code ? PAYMENT_METHOD_NAME[method.code] : '',
      items,
    },
  };
  pushObjectDataLayer(data, triggerSelectedPaymentMethodEvent.name);
};

export const triggerPurchaseEvent = async (payload: IOrderSuccess, Fingerprint: string | null) =>
  new Promise<void>(resolve => {
    payload.orders.forEach(order => {
      const data = {
        event: 'purchase',
        Fingerprint,
        ecommerce: {
          transaction_id: order.orderNumber,
          value: order.totalAmount,
          currency: DEFAULT_CURRENCY.id,
          tax: order.igvAmount,
          affiliation: 'null',
          coupon: 'null',
          shipping: 0,
          items: order.products.map(product => {
            const { metadata } = product;
            return {
              item_id: product.sku,
              item_name: product.name,
              price: product.price,
              quantity: product.quantity,
              presentacion: product.presentation,
              product_sku: product.sku,
              discount: getProductTotalDiscountValue(product.promos),
              procedencia: product.providerDisplayName,
              item_brand: product.brand,
              item_category: product.categoryName,
              item_category2: product.subcategoryName,
              item_list_id: metadata.itemListName,
              item_list_name: metadata.itemListName,
              index: metadata.index,
              product_ean: product.eanCode || 'no-existe',
              metadata: metadata.origin,
              dex: product.distributorName,
            };
          }),
        },
      };
      pushObjectDataLayer(data, triggerPurchaseEvent.name);
    });
    resolve();
  });
