import { customersService } from 'core/services/customers/customers.service';
import type { TSliceExtraReducer } from 'core/store';
import { createAppAsyncThunk } from 'core/store/store.utils';
import { SLICE_NAMESPACE } from '../constants';
import { IAuthState } from '../types';

export const loadProfileAction = createAppAsyncThunk(`${SLICE_NAMESPACE}/loadProfile`, (customerId: number) =>
  customersService.getProfileUser(customerId),
);

export const loadProfileReducer: TSliceExtraReducer<IAuthState> = builder =>
  builder
    .addCase(loadProfileAction.pending, state => {
      state.error = null;
      state.loading = true;
    })
    .addCase(loadProfileAction.fulfilled, (state, action) => {
      state.customer = action.payload;
      state.loading = false;
    })
    .addCase(loadProfileAction.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });
