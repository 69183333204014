import { useEffect } from 'react';
import {
  DEX_WITH_DEBT_MESSAGE,
  HYBRID_WITH_DEBT_IN_APUDEX_MESSAGE,
  HYBRID_WITH_DEBT_IN_BOTH_SOURCES_MESSAGE,
  HYBRID_WITH_DEBT_IN_DEX_MESSAGE,
} from 'core/constants/credit.constants';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { authGetCustomerSource } from 'core/store/auth/selectors';
import { hasDebtBySourceSelector, hasDebtSelector } from 'core/store/credits/selectors';
import { useAppSelector } from 'core/store/store.utils';

import './debt-banner.scss';

interface IDebtBannerProps {
  origin?: string;
}

// TODO: Debt banner - uncomment
// interface IBannerProps {
//   text: string;
// }

// const Banner = ({ text }: IBannerProps) => (
//   <div data-testid="debt-banner" className="debt-fixed-banner">
//     <span className="debt-fixed-banner__icon">☝️</span>
//     <span className="debt-fixed-banner__text">{text}</span>
//   </div>
// );
export const DebtBanner = ({ origin }: IDebtBannerProps) => {
  const customerSource = useAppSelector(authGetCustomerSource);
  const hasDebt = useAppSelector(hasDebtSelector);
  const { hasDebtInApudex, hasDebtInDex } = useAppSelector(hasDebtBySourceSelector);

  const getDebtMessage = () => {
    if (customerSource === ECustomerSource.HYBRID) {
      if (hasDebtInDex && hasDebtInApudex) {
        return HYBRID_WITH_DEBT_IN_BOTH_SOURCES_MESSAGE;
      }
      if (hasDebtInDex) {
        return HYBRID_WITH_DEBT_IN_DEX_MESSAGE;
      }
      if (hasDebtInApudex) {
        return HYBRID_WITH_DEBT_IN_APUDEX_MESSAGE;
      }
    }

    if (customerSource === ECustomerSource.DEX && hasDebtInDex) {
      return DEX_WITH_DEBT_MESSAGE;
    }
  };

  const debtMessage = getDebtMessage() ?? '';

  useEffect(() => {
    if (hasDebt && origin) {
      // TODO: Debt banner - uncomment
      // triggerViewCreditAlert({ zone: origin, cta: debtMessage });
    }
  }, [debtMessage, hasDebt, origin]);

  // TODO: Debt banner - uncomment
  // if (!hasDebt) return null;

  // return customerSource === ECustomerSource.APUDEX ? (
  //   <a href={CREDIT_DEBT_LINK_APUDEX} target="_blank" rel="noreferrer">
  //     <Banner text={APUDEX_WITH_DEBT_MESSAGE} />
  //   </a>
  // ) : (
  //   <Banner text={debtMessage} />
  // );
  return null;
};
